<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('Description')"
      icon="mdi-image-text"
      :header-action="saveChanges"
      :header-action-text="$t('Save')"
  >
    <v-sheet class="wsRoundedHalf mt-6">

      <v-fade-transition mode="out-in">

          <v-sheet v-if="$store.state.courses.course.img && !uploadLogo" width="100%"  class="wsRoundedHalf">
            <v-hover v-slot="{hover}">
              <v-img transition="xxx" class="wsRoundedHalf pointer"  width="100%" height="300"   :src="$store.state.courses.course.img">
                <template slot="default">
                  <v-sheet v-if="hover" dark @click="uploadLogo = true"   color="#00000099" class="fill-height d-flex align-center justify-center">
                    <v-icon class="mr-2">mdi-pencil</v-icon>
                    <h3>{{ $t('ChangeImage') }} </h3>
                  </v-sheet>
                </template>
              </v-img>
            </v-hover>
          </v-sheet>
          <div v-else>
            <div class="d-flex align-center justify-space-between mb-3">
              <h3>{{ $t('UploadCourseImage')}}</h3>
              <v-btn @click="uploadLogo = false" :color="wsACCENT" class="noCaps" text> {{ $t('Cancel') }}</v-btn>
            </div>
            <ws-file-uploader  public image @success="updateCourseImage" />

          </div>

      </v-fade-transition>

      <ws-text-field class="mt-6"
          :label="$t('Name')"
          :placeholder="$t('CourseName')"
          v-model="entityData.name"
      />
      <ws-text-field
          v-if="HAS_ONLINE_PAYMENT"
          v-model="entityData.payment_name"
          :label="$t('FiscalizationName')"
          :placeholder="$t('EnterFiscalizationName')"
          :tooltip="$t('FiscalizationNameTooltip')"
          class="mt-6"
          clearable
      />
      <ws-text-field
          v-if="HAS_ONLINE_PAYMENT"
          v-model="entityData.product_id"
          :label="$t('FiscalizationId')"
          :placeholder="$t('EnterFiscalizationId')"
          :tooltip="$t('FiscalizationIdTooltip')"
          class="mt-6"
          clearable
      />
      <ws-text-field
          class="mt-6"
          v-model="entityData.short_description"
          :label="$t('ShortDescription')"
          :placeholder="$t('EnterShortDescription')"
          height="100"
          counter="200"
          area
      />


      <h5 class="mb-2" style="color : var(--wsMIDDLE);">{{ $t('Description') }}</h5>
      <v-sheet min-height="200" :style="`border : 1px solid ${wsDARKLIGHT}`" class="wsRoundedLight pt-3 mb-16 pb-7">
        <ws-text-editor
            v-model="entityData.description"
            :html="entityData.description_text"
            :value="[]"
            is-public
        />
      </v-sheet>
      <v-sheet height="150"></v-sheet>


    </v-sheet>
  </sub-page>



</template>

<script>

import {mapActions, mapMutations, mapState} from "vuex";
export default {
  name: "courseDescription",
  props : ['uuid'],
  data() {
    return {
      uploadLogo : false,
      entityData : {
        short_description : '',
        description : [],
        name : ''
      }
    }
  },
  computed : {
  ...mapState('courses', [ 'course' , 'selectedLang' ]),
  },
  watch : {
    course(val) {
      this.entityData = JSON.parse(JSON.stringify(val))
    },
    selectedLang() {
      this.initPage()
    }
  },

  methods : {
    ...mapActions('courses', [ 'ADD_COURSE' , 'GET_COURSE' , 'EDIT_COURSE_IMAGE' ]),
    ...mapMutations('courses' , ['INIT_PAGE']),
    ...mapActions('upload', [ 'UPLOAD_COURSE_IMAGE',  ]),

    async updateCourseImage($event) {

      let data = {
        course_id : this.uuid,
        file_id : $event.uuid
      }
      let result = await this.EDIT_COURSE_IMAGE(data)
      if ( !result ) {
        return
      }
      this.$store.state.courses.course.img = $event.url
      this.uploadLogo = false
    },

    async updateLogo(file) {
      let data = {
        file : file,
        data : {
          course : this.uuid
        },
        entityDataDefault : {
          name : {},
          description : {},
          short_description: {},
          img : null,
        }
      }
      let result = await this.UPLOAD_COURSE_IMAGE(data)
      if ( !result ) { return this.notify('ConnectionError') }

      this.$store.state.courses.course.img = result.url
      this.uploadLogo = false
    },

    async saveChanges() {

      this.entityData.lang = this.selectedLang

      let result = await this.ADD_COURSE(this.entityData)
      if (!result) { return this.notify( this.$t('NetworkError') )}
      this.notify(this.$t('ChangesSaved'))
      this.$store.state.courses.course = result;

    },
    async initPage() {

      let result = await this.GET_COURSE({ uuid : this.uuid, lang : this.selectedLang} )
      if ( !result ) {
        return
      }
      this.entityData = result

    }

  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>